<template>
  <div id="home">
    <banner/>
    <about />
    <garden />
    <blog />
    <!--<paper />-->
    <!--<info />-->

    <!--<subscribe />-->

    <!--//<social />-->
  </div>
</template>

<script>
   export default {
    name: 'Home',

    components: {
      Banner: () => import('@/components/home/Banner'),
      About: () => import('@/components/home/About'),
      //Access: () => import('@/components/home/Access'),
      //Info: () => import('@/components/home/Info'),
      Garden: () => import('@/components/home/Garden'),
      Blog: () => import('@/components/home/Blog'),
      //Paper: () => import('@/components/home/Paper'),
      //Social: () => import('@/components/home/Social'),
      //Subscribe: () => import('@/components/home/Subscribe'),
    },
  }
</script>

<style scoped>
.red {
  height: auto;
  background-color: red;
  color: white;
}
.vsa-item__heading {
  background-color: red;
}
</style>
