<template>
  <v-app>
    <!--<core-app-bar />-->
    <core-view />
    <core-footer />
  </v-app>
</template>

<script>
  export default {
    name: 'App',

    components: {
      //CoreAppBar: () => import('@/components/core/AppBar'),
      CoreView: () => import('@/components/core/View'),
      CoreFooter: () => import('@/components/core/Footer'),
      //CoreCta: () => import('@/components/core/Cta'),
      //CoreDrawer: () => import('@/components/core/Drawer'),
    },

  }
</script>
<style>
.block {
  display: block;
}
.none {
  display: none;
  overflow: hidden;
}
.accordion {
  background-color: rgb(82, 149, 236);
  color: white;
  cursor: pointer;
  /*padding: 10px; */
  padding: 0px;
  height: auto;
  width: 100%;
  border-style: solid;
  border-color: blue;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 1s;
}

.active, .accordion:hover {
  background-color: #ccc; 
}

.panel {
/*  padding: 0 18px;*/
  padding: 0;
  display: none;
  background-color: white;
  overflow: hidden;
}
.halfline{
  height: 6px;
}

.myAccessContainer {
  width:100%;
  margin: 0px;
}
.myAccessSubtitle {
  background-color: lightseagreen;
  color: black;
/*  margin: 4px;
  padding: 4px;*/
  margin: 0px;
  padding: 0px;
}


.Accordion {
  margin: 0;
  padding: 0;
  border: 1px solid hsl(0, 0%, 82%);
  border-radius: 7px;
  /*width: 20em;*/
  width: auto;
}

.Accordion h3 {
  margin: 0;
  padding: 0;
}

.Accordion.focus {
  border-color: hsl(216, 94%, 73%);
}

.Accordion.focus h3 {
  background-color: hsl(0, 0%, 97%);
}

.Accordion > * + * {
  border-top: 1px solid hsl(0, 0%, 82%);
}

.Accordion-trigger {
  background: none;
  color: hsl(0, 0%, 13%);
  display: block;
  font-size: 1rem;
  font-weight: normal;
  margin: 0;
  /*padding: 1em 1.5em;*/
  padding: 0;
  position: relative;
  text-align: left;
  width: 100%;
  outline: none;
}

.Accordion-trigger:focus,
.Accordion-trigger:hover {
  background: hsl(216, 94%, 94%);
}

.Accordion *:first-child .Accordion-trigger {
  border-radius: 5px 5px 0 0;
}

button {
  border-style: none;
}

.Accordion button::-moz-focus-inner {
  border: 0;
}

.Accordion-title {
  display: block;
  pointer-events: none;
  border: transparent 2px solid;
  border-radius: 5px;
  padding: 0.25em;
  outline: none;
}

.Accordion-trigger:focus .Accordion-title {
  border-color: hsl(216, 94%, 73%);
}

.Accordion-icon {
  border: solid hsl(0, 0%, 62%);
  border-width: 0 2px 2px 0;
  height: 0.5rem;
  pointer-events: none;
  position: absolute;
  right: 2em;
  top: 50%;
  transform: translateY(-60%) rotate(45deg);
  width: 0.5rem;
}

.Accordion-trigger:focus .Accordion-icon,
.Accordion-trigger:hover .Accordion-icon {
  border-color: hsl(216, 94%, 73%);
}

.Accordion-trigger[aria-expanded="true"] .Accordion-icon {
  transform: translateY(-50%) rotate(-135deg);
}

.Accordion-panel {
  margin: 0;
  /*padding: 1em 1.5em;*/
  padding: 0;
}

/* For Edge bug https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/4806035/ */
.Accordion-panel[hidden] {
  display: none;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

input {
  border: 1px solid hsl(0, 0%, 62%);
  border-radius: 0.3em;
  display: block;
  font-size: inherit;
  padding: 0.3em 0.5em;
}


.mypagetitle {
  font-weight: bold;
  padding: 0px;
  margin-top: 8px;
  margin-left: 0px;
  margin-bottom: 0px;
  color: black;
  font-size: 25px;
}

.mypagesubtitle {
  font-weight: bold;
  padding: 0px;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
  color: black;
  font-size: 19px;
}
</style>