import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    current_lang: 'jp',
    current_dataurl: '',
    pagesummary: {},
    accordion: [],
    photos: [],

    articles: require('@/data/articles.json'),

    //about: require('@/data/about.json'),
    drawer: false,
    items: [
      {
        text: 'Home',
        href: '#!',
      },
      {
        text: 'About',
        href: '#about',
      },
      {
        text: 'Gardens',
        href: '#garden',
      },
      {
        text: 'Info',
        href: '#nearby',
      },
    ],
  },
  getters: {
    current_dataurl(state) { return state.current_dataurl },
    pagesummary(state) {
      return state.pagesummary
    },
    accordion(state) {
      //console.log(state.accordion[0].title)
      //console.log(state.accordion[0].content[0].des)
      return state.accordion
    },
    photos(state) {
        return state.photos
    },

    categories: state => {
      const categories = []

      for (const article of state.articles) {
        if (
          !article.category ||
          categories.find(category => category.text === article.category)
        ) continue

        const text = article.category

        categories.push({
          text,
          href: '#!',
        })
      }

      return categories.sort().slice(0, 4)
    },
/*    links: (state, getters) => {
      return state.items.concat(getters.categories)
    },
*/
    links: (state, getters) => {
      return state.items
    },
    articles: (state) => {
      return state.articles
    },
    stCurrentLang: (state) => {
      return state.current_lang
      //return 'jp';
    },

  },
  mutations: {
    set_current_room: (state, payload) => (state.current_room = payload),
    set_current_lang: (state, payload) => (state.current_lang = payload),
    set_current_data(state, payload) {
      state.pagesummary = payload.pagesummary
      state.accordion = payload.accordion
      state.photos = payload.photos
    },
    set_current_dataurl(state, payload) { state.current_dataurl = payload },

    //setDrawer: (state, payload) => (state.drawer = payload),
    //toggleDrawer: state => (state.drawer = !state.drawer),
  },
  actions: {
    set_current_lang ( context, payload  ) {
      context.commit('set_current_lang', payload)
    },
    set_current_data(context, payload) { context.commit('set_current_data', payload) },

    set_current_dataurl(context, payload) { context.commit('set_current_dataurl', payload) },
  },
})
